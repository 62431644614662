import { useState, useEffect, useRef, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const apiUrl = process.env.REACT_APP_API_URL;
const MySwal = withReactContent(Swal);
export default function AddSale({addSaleModalSetting, products,
  customers,
  stores,
  handlePageUpdate,
  authContext
}) {

  const [sale, setSale] = useState({
    userId: authContext.user,
    customerID: "",
    storeID: "",
    saleDate: "",
    totalSaleAmount: 0,
    productDetails: [], // Stores added products
  });

  const [customer, setCustomer] = useState({
    customerName: "",
    customerMobile: "",
    customerAddress: "",
    customerEmail:""
  });
  const [currentProduct, setCurrentProduct] = useState({
    productID: "",
    stockSold: 1,
    totalSaleAmount: 0,
    finalAmount:0
  });
  console.log("asdsd")
  const [open, setOpen] = useState(true);
  // const [returnmod, setReturnmod] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // Search query state for customer
  const [addCustomerModalOpen, setAddCustomerModalOpen] = useState(false); // State for add customer modal

  const cancelButtonRef = useRef(null);

  const handleInputChange = (key, value) => {
    console.log(key,value)
    setSale({ ...sale, [key]: value });
  };

  const handleInputChangeCustomer = (key, value) => {
    console.log(key,value)
    setCustomer({ ...customer, [key]: value });
  };

 const addSale = () => {
      if(sale.productDetails.length<1){
        sale.productDetails=[currentProduct];
        sale.totalSaleAmount=currentProduct.totalSaleAmount
      }
      console.log("sale is", sale)
      fetch(apiUrl+"/api/sales/add", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(sale),
      }).then((result) => {
        MySwal.fire({
            position: "top-end",
            icon: "success",
            title: "Bill Generated Successfully",
            showConfirmButton: false,
            timer: 2000
          });
          handlePageUpdate();
          addSaleModalSetting();
      }).catch((err) => console.log(err));
  };
  
  const handleProductChange = (key, value) => {
    const updatedProduct = { ...currentProduct, [key]: value };
    console.log("===updatedProduct======",updatedProduct,key,value)
    if (key==='totalSaleAmount' || key==='stockSold') {
      const product = products.find((p) => p.id === updatedProduct.productID);
      if (product) {
        console.log("here is product",currentProduct.totalSaleAmount)
        updatedProduct.finalAmount = parseInt(updatedProduct.totalSaleAmount) * parseInt(updatedProduct.stockSold);
      }
    }

    setCurrentProduct(updatedProduct);
    console.log("====currentProduct====",updatedProduct)
  };

  const addProductToList = () => {
    if (!currentProduct.productID || currentProduct.stockSold <= 0) {
      alert("Please select a valid product and quantity.");
      return;
    }
    console.log("========currentProduct===========",currentProduct)
    setSale((prev) => ({
      ...prev,
      productDetails: [...prev.productDetails, currentProduct],
      totalSaleAmount:
        prev.totalSaleAmount + parseFloat(currentProduct.totalSaleAmount),
    }));
    setCurrentProduct({ productID: "", stockSold: 1, totalSaleAmount: 0 });

    console.log("========sale====", sale)
    console.log("========setCurrentProduct====", currentProduct)
  };


  const removeProduct = (index) => {
    const updatedProducts = [...sale.productDetails];
    const removedProduct = updatedProducts.splice(index, 1)[0];

    setSale((prev) => ({
      ...prev,
      productDetails: updatedProducts,
      totalSaleAmount: prev.totalSaleAmount - removedProduct.totalSaleAmount,
    }));
  };
 
  const addCustomer = () => {
    fetch(apiUrl+"/api/customer/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(customer),
    })
      .then((response) => {
        if (!response.ok) {
          // If the response is not successful, throw an error
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Parse JSON from the response
      })
      .then((data) => {
        alert("Customer ADDED");
        setSearchQuery("");
        // Add the newly created customer to the local list (optional)
        customers.push({
          id: data.customer.id, // Assuming your API response includes `customer.id`
          customerName: data.customer.customerName,
          customerMobile: data.customer.customerMobile,
        });
  
        console.log("----Updated customers----", customers);
  
        // Close the modal
        setAddCustomerModalOpen(false);
      })
      .catch((err) => {
        console.error("Error adding customer:", err);
        alert("Failed to add customer. Please try again.");
      });
  };
  

  // Filter customers by name or mobile based on search query
  const filteredCustomers = customers.filter(
    (customer) =>
      customer.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      customer.customerMobile.toString().includes(searchQuery)
  );

  const handleCustomerSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(()=>{

  },[])
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg overflow-y-scroll">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                        <PlusIcon
                          className="h-6 w-6 text-blue-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg py-4 font-semibold leading-6 text-gray-900"
                        >
                          Add Sale
                        </Dialog.Title>
                        <form action="#">
                          <div className="grid gap-4 mb-4 sm:grid-cols-2">
                            {/* Customer Dropdown with Search */}
                            <div>
                              <label
                                htmlFor="customerID"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Customer
                              </label>
                              <div className="relative">
                                <input
                                  type="text"
                                  id="customerSearch"
                                  name="customerSearch"
                                  value={searchQuery}
                                  onChange={handleCustomerSearch}
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                                  placeholder="Search by Name or Mobile"
                                />
                                <select
                                  id="customerID"
                                  name="customerID"
                                  onChange={(e) =>
                                    handleInputChange(e.target.name, e.target.value)
                                  }
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mt-2"
                                >
                                  <option value="">Select Customer</option>
                                  {filteredCustomers.map((customer) => (
                                    <option key={customer.id} value={customer.id}>
                                      {customer.customerName} - {customer.customerMobile}
                                    </option>
                                  ))}
                                </select>
                                {filteredCustomers.length === 0 && searchQuery && (
                                  <button
                                    type="button"
                                    onClick={() => setAddCustomerModalOpen(true)}
                                    className="mt-2 text-blue-500"
                                  >
                                    Add Customer
                                  </button>
                                )}
                              </div>
                            </div>

                            {/* Product Dropdown */}
                            <div>
                              <label
                                htmlFor="productID"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Product Name
                              </label>
                              <select
                                id="productID"
                                name="productID"
                                value={currentProduct.productID}
                                onChange={(e) =>
                                  handleProductChange("productID", e.target.value)
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                              >
                                <option value="">Select Product</option>
                                {products.map((product) => (
                                  <option key={product.id} value={product.id}>
                                    {product.name}
                                  </option>
                                ))}
                              </select>
                              
                            </div>

                            {/* Store Dropdown */}
                            {/* <div>
                              <label
                                htmlFor="storeID"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Store
                              </label>
                              <select
                                id="storeID"
                                name="storeID"
                                onChange={(e) =>
                                  handleInputChange(e.target.name, e.target.value)
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                              >
                                <option value="">Select Store</option>
                                {stores.map((store) => (
                                  <option key={store.id} value={store.id}>
                                    {store.name}
                                  </option>
                                ))}
                              </select>
                            </div> */}

                            {/* Stock Sold */}
                            <div>
                              <label
                                htmlFor="stockSold"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Stock Sold
                              </label>
                              <input
                                type="number"
                                name="stockSold"
                                id="stockSold"
                                value={currentProduct.stockSold}
                                onChange={(e) =>
                                  handleProductChange("stockSold", e.target.value)
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                placeholder="0 - 999"
                              />
                            </div>

                            
                            {/* <div>
                              <label
                                htmlFor="saleDate"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Sale Date
                              </label>
                              <input
                                type="date"
                                name="saleDate"
                                id="saleDate"
                                value={sale.saleDate}
                                onChange={(e) =>
                                  handleInputChange(e.target.name, e.target.value)
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                              />
                            </div> */}

                            {/* Total Sale Amount */}
                            <div>
                              <label
                                htmlFor="totalSaleAmount"
                                className="block mb-2 text-sm font-medium text-gray-900"
                              >
                                Total Amount
                              </label>
                              <input
                                type="number"
                                name="totalSaleAmount"
                                id="totalSaleAmount"
                                value={currentProduct.totalSaleAmount}
                                onChange={(e) =>
                                  handleProductChange("totalSaleAmount", e.target.value)
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                placeholder="Total Amount"
                              />
                            </div>
                            
                          </div>
                        </form>
                      </div>
                    </div>
                    {sale.productDetails.length > 0 && (
                      <div>
                        <h4 className="font-semibold mt-4">Purchase Summary</h4>
                        {sale.productDetails.map((product, index) => (
                          <div
                            key={index}
                            className="flex gap-2 items-center justify-between border p-2 rounded"
                          >
                            <span>
                              {products.find((p) => p.id === product.productID)?.name}
                            </span>
                            <span>Qty: {product.stockSold}</span>
                            <span>₹{product.finalAmount}</span>
                            <button
                              type="button"
                              className="text-red-500"
                              onClick={() => removeProduct(index)}
                            >
                              Remove
                            </button>
                          </div>
                        ))}

                      
                        <div className="flex gap-2 items-center justify-between border-t pt-2 mt-2 font-semibold">
                          <span>Total</span>
                          <span>
                            Qty:{" "}
                            {sale.productDetails.reduce((total, product) => parseInt(total) + parseInt(product.stockSold), 0)}
                          </span>
                          <span>
                            ₹
                            {sale.productDetails.reduce((total, product) => total + product.finalAmount, 0)}
                          </span>
                        </div>

                        
                      </div>
                    )}
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto"
                      onClick={addProductToList}
                    >
                      + Add Product
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto"
                      onClick={addSale}
                    >
                      Save Sale
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto"
                      onClick={addSaleModalSetting}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    
      <Transition.Root show={addCustomerModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setAddCustomerModalOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                        <PlusIcon
                          className="h-6 w-6 text-blue-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg py-4 font-semibold leading-6 text-gray-900"
                        >
                          Add Customer
                        </Dialog.Title>
                        <form action="#">
                          <div className="row">
                            <div className="col-md-6">
                              <label
                                htmlFor="customerName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Customer Name
                              </label>
                              <input
                                type="text"
                                id="customerName"
                                name="customerName"
                                value={customer.customerName}
                                onChange={(e) =>
                                  handleInputChangeCustomer(e.target.name, e.target.value)
                                }
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Enter Name"
                              />
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="customerMobile"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Mobile Number
                              </label>
                              <input
                                type="text"
                                id="customerMobile"
                                name="customerMobile"
                                value={customer.customerMobile}
                                onChange={(e) =>
                                  handleInputChangeCustomer(e.target.name, e.target.value)
                                }
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Enter Mobile Number"
                              />
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="customerMobile"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Email-ID
                              </label>
                              <input
                                type="text"
                                id="customerEmail"
                                name="customerEmail"
                                value={customer.customerEmail}
                                onChange={(e) =>
                                  handleInputChangeCustomer(e.target.name, e.target.value)
                                }
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Enter Email-Id"
                              />
                            </div>
                          </div>
                          <div className="mb-4">
                            <label
                              htmlFor="customerMobile"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Address
                            </label>
                            <input
                              type="text"
                              id="customerAddress"
                              name="customerAddress"
                              value={customer.customerAddress}
                              onChange={(e) =>
                                handleInputChangeCustomer(e.target.name, e.target.value)
                              }
                              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                              placeholder="Enter Address"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto"
                      onClick={addCustomer}
                    >
                      Save Customer
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto"
                      onClick={() => setAddCustomerModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
