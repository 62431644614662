import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import AddSale from "../components/AddSale";
import AuthContext from "../AuthContext";
const apiUrl = process.env.REACT_APP_API_URL;
function Sales() {
  const [showSaleModal, setShowSaleModal] = useState(false);
  const [showReturnModal, setShowReturnModal] = useState(false);
  const navigate = useNavigate();
  const [sales, setAllSalesData] = useState([]);
  const [products, setAllProducts] = useState([]);
  const [stores, setAllStores] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [updatePage, setUpdatePage] = useState(true);

  const authContext = useContext(AuthContext);


  // Fetching Data of All Sales
  const fetchSalesData = useCallback(
    () => {
      fetch(apiUrl+`/api/sales/get/${authContext.user}`)
        .then((response) => response.json())
        .then((data) => {
          setAllSalesData(data);
        })
        .catch((err) => console.log(err));
    },
    [authContext.user]
  )

  // Fetching Data of All Products
  const fetchProductsData = useCallback(() => {
    fetch(apiUrl+`/api/product/get/${authContext.user}`)
      .then((response) => response.json())
      .then((data) => {
        setAllProducts(data);
      })
      .catch((err) => console.log(err));
  },[authContext.user])

  // Fetching Data of All Stores
  const fetchStoresData =useCallback( () => {
    fetch(apiUrl+`/api/store/get/${authContext.user}`)
      .then((response) => response.json())
      .then((data) => {
        setAllStores(data);
      });
  },[authContext.user]);
  const fetchCustomers = () => {
    fetch(apiUrl+`/api/customer/get`)
      .then((response) => response.json())
      .then((data) => {
        setCustomers(data);
      });
  };

  useEffect(() => {
    fetchSalesData();
    fetchProductsData();
    fetchStoresData();
    fetchCustomers()
  }, [updatePage,fetchSalesData, fetchProductsData,fetchStoresData]);

  // Modal for Sale Add
  const addSaleModalSetting = () => {
    setShowSaleModal(!showSaleModal);
  };
  const returnProductSetting = () => {
    setShowReturnModal(!showReturnModal);
  }

  // Handle Page Update
  const handlePageUpdate = () => {
    setUpdatePage(!updatePage);
  };

  const viewProductDetails =(id) => {
    navigate('/sales/'+id);
  }
  return (
    <div className="col-span-12 lg:col-span-10  flex justify-center">
      <div className=" flex flex-col gap-5 w-11/12">
        {showSaleModal && (
          <AddSale
            addSaleModalSetting={addSaleModalSetting}
            products={products}
            stores={stores}
            customers={customers}
            handlePageUpdate={handlePageUpdate}
            authContext={authContext}
          />
        )}
      
        <div className="overflow-x-auto rounded-lg border bg-white border-gray-200 ">
          <div className="flex justify-between pt-5 pb-3 px-3">
            <div className="flex gap-4 justify-center items-center ">
              <span className="font-bold">Sales</span>
            </div>
            <div className="flex gap-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-xs  rounded"
                onClick={addSaleModalSetting}
              >
                Add Sales
              </button>
            </div>
          </div>
          <table className="min-w-full divide-y-2 divide-gray-200 text-sm">
            <thead>
              <tr>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Product Name
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Customer Name
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Stock Sold
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Sales Date
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Total Sale Amount
                </th>
                <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900">
                  Action
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {sales.map((element, index) => {
                return (
                  <tr key={element.id}>
                    <td className="whitespace-nowrap px-4 py-2  text-gray-900">
                      {element.Product?.name}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.Customer?.customerName}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.stockSold}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.saleDate}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      {element.totalSaleAmount}
                    </td>
                    <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                      <button className="btn btn-primary bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-xs  rounded" onClick={()=>viewProductDetails(element.id)} >
                        View
                      </button>
                      <span>-</span>
                      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 text-xs  rounded" onClick={returnProductSetting} >
                        Return
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Sales;
